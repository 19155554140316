export const TEST_MODE = true;

export const WalletType = {
    Xverse: 'Xverse',
    Unisat: 'Unisat',
    Leather: 'Leather',
    Phatom: 'Phantom',
    MagicEden: 'MagicEden',
    OKX: 'OKX'
}

export const BACKEND_URL_PURE = 'https://server.membula.io';
// export const BACKEND_URL_PURE = 'http://localhost:9200';
export const BACKEND_URL = `${BACKEND_URL_PURE}/api`;
export const NEXT_PUBLIC_ORDINAL_URL=`https://testnet-explorer.ordinalsbot.com/content`
export const UNISAT_API_URL = `https://api-testnet.unisat.io`;

export const XL_WIDTH = 1320;
export const L_WIDTH = 1120;
export const MD_WIDTH = 990;
export const S_WIDTH = 660;
export const XS_WIDTH = 568