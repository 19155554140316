import { useContext, useEffect, useRef, useState } from 'react'
import Notiflix, { Loading } from "notiflix";
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import axios from 'axios';
import { RiArrowDownSLine } from "react-icons/ri";

import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import { IAuctionLIst, IModalList, IRuneBalance, IRuneUtxoBalance } from '../type';
import Header from '../component/Header';
import { BACKEND_URL, WalletType } from '../configs/config';
import { useParams } from 'react-router-dom';
import { BitcoinSVG } from '../component/BitcoinSVG';
import { EmptySVG } from '../component/emptySVG';
import { LoadingInitialInfo } from '../component/LoadingUpdate';
import { useNavigate, useNavigation } from 'react-router-dom';


export default function RuneMarketplaceDetails() {

    const appContext = useContext(AppContext);

    const [opened, { open, close }] = useDisclosure(false);

    const [auctionList, setAuctionList] = useState<IAuctionLIst[]>();

    const [dropDownModalFlag, setDropDownModalFlag] = useState(false);
    const [runeBalanceList, setRuneBalanceList] = useState<IRuneBalance[]>();
    const [selectedList, setSelectedList] = useState<IRuneBalance>();
    const [tempList, setTempList] = useState<IModalList[]>();
    const [runeUtxoList, setRuneUtxoList] = useState<IRuneUtxoBalance[]>();
    const [price, setPrice] = useState<string>('');

    const unitPriceRef = useRef(null);

    const { tick } = useParams();
    console.log("tick ==> ", tick);

    const navigation = useNavigate();

    Loading.custom({
        svgColor: "orange"
    })

    const fetchRuneDetailsInfo = async (runeTicker: string) => {
        console.log("fetchRuneDetailsInfo runeTicker ==>", runeTicker);
        const result = (await axios.post(`${BACKEND_URL}/marketplace/getAuctionList`, {
            tick: runeTicker
        })).data;

        console.log("result on fetchRUneDetailsINfo ==> ", result);
        if (result.success) {
            const list = result.payload.list;
            setAuctionList(list);
        } else {
            Notiflix.Notify.failure("Invalid Rune ticker...")
        }

        Notiflix.Loading.remove();
    }

    const onCreateBid = async (list: IAuctionLIst) => {
        try {
            LoadingInitialInfo();
            const bidResult = (await axios.post(`${BACKEND_URL}/marketplace/create-bid`, {
                auctionId: list.auctionId,
                bidPrice: list.price,
                address: appContext?.paymentAddress,
                pubkey: appContext?.paymentPublickey
            })).data;

            if (!bidResult.success) {
                Notiflix.Notify.failure(bidResult.message);
            } else {
                Notiflix.Notify.success("Get psbt successfully.");
                const toSignInputs: any[] = [];
                bidResult.payload.bidSignIndexes.map((index: number) => toSignInputs.push({
                    index,
                    address: appContext?.paymentAddress
                }))

                const signedPsbt = await (window as any).unisat.signPsbt(bidResult.payload.psbt, {
                    autoFinalized: false,
                    toSignInputs
                })

                console.log("signedPsbt ==> ", signedPsbt);

                const finalResult = (await axios.post(`${BACKEND_URL}/marketplace/confirm-bid`, {
                    auctionId: list.auctionId,
                    bidId: bidResult.payload.bidId,
                    fromBase64: false,
                    psbtBid: signedPsbt,
                    psbtBid2: "",
                    psbtSettle: "",
                    walletType: appContext?.walletType
                })).data;

                if (finalResult.success) {
                    Notiflix.Notify.success(finalResult.message);
                    Notiflix.Notify.success(finalResult.payload);
                } else {
                    Notiflix.Notify.failure(finalResult.message);
                }

                console.log("finalResult ==> ", finalResult);
            }

            Notiflix.Loading.remove();
            console.log("bidResult ==> ", bidResult);

        } catch (error) {
            Notiflix.Notify.info("User reject the sign");
            Notiflix.Loading.remove();
        }
    }

    const triggerDropDownHandler = async () => {
        if (dropDownModalFlag) {
            setDropDownModalFlag(false);
            return;
        }

        const runeBalancePayload = (await axios.post(`${BACKEND_URL}/marketplace/get-rune-balance`, {
            ordinalAddress: appContext?.ordinalsAddress
        })).data;

        console.log("runeBalancePayload ==> ", runeBalancePayload);

        if (!runeBalancePayload.success) {
            Notiflix.Notify.failure(runeBalancePayload.message);
            return
        }
        console.log("runeBalancePayload.payload.details ==> ", runeBalancePayload.payload.detail);
        setRuneBalanceList(runeBalancePayload.payload.detail);

        setDropDownModalFlag(true);
    }

    const getRuneUtxoFunc = async (list: IRuneBalance) => {
        setPrice('');
        console.log("list ==> ", list);
        setDropDownModalFlag(false);
        const specificRuneBalancePayload = (await axios.post(`${BACKEND_URL}/marketplace/get-rune-utxo`, {
            ordinalAddress: appContext?.ordinalsAddress,
            runeId: list.runeid
        })).data;

        console.log("specificRuneBalancePayload ==> ", specificRuneBalancePayload);

        if (specificRuneBalancePayload.success) {
            Notiflix.Notify.success(specificRuneBalancePayload.message);
            const initUtxoList: IRuneUtxoBalance[] = specificRuneBalancePayload.payload.utxo;
            const auctionList = await onSetTempListFunc(list.spacedRune);
            const filterList: IRuneUtxoBalance[] = [];
            initUtxoList.map((utxo: IRuneUtxoBalance) => {
                if(auctionList?.some(auction => auction.index == utxo.vout && auction.txid == utxo.txid)){
                    filterList.push({...utxo, listed: true});
                } else {
                    filterList.push({...utxo, listed: false});
                }
            })

            console.log("filterList ==> ", filterList);

            setRuneUtxoList(filterList);
            setSelectedList(list);
            open();
        } else {
            Notiflix.Notify.failure(specificRuneBalancePayload.message);
        }
    }

    const onChangeFunc = async (e: any) => {
        console.log("input value ==> ", e.target.value);
        setPrice(e.target.value);
    }

    const onListFunc = async (list: IRuneUtxoBalance) => {
        try {
            if(list.listed) {
                Notiflix.Notify.warning("This is already listed...");
                return
            }
            const unitPrice = (unitPriceRef.current as any).value;
            console.log("unitPrice ==> ", unitPrice);
            if (!unitPrice) {
                Notiflix.Notify.failure("Please enter price first...")
                return
            }
            const initialPrice = unitPrice * list.runes[0].amount;
            console.log("initialPrice ==> ", initialPrice);

            if (initialPrice < 5000 || initialPrice > 50000000) {
                Notiflix.Notify.failure("Init price should be between 5000 sats and 5BTC")
                return
            }

            const preListingPayload = (await axios.post(`${BACKEND_URL}/marketplace/create_put_on`, {
                "txid": list.txid,
                "index": list.vout,
                "initPrice": initialPrice.toString(),
                "unitPrice": unitPrice,
                "pubkey": appContext?.ordinalsPublickey
            })).data;

            if (!preListingPayload.success) {
                Notiflix.Notify.failure(preListingPayload.message);
                return
            }

            const auctionId = preListingPayload.payload.auctionId;
            const psbt = preListingPayload.payload.psbt;
            const index = preListingPayload.payload.signIndexes[0];

            console.log("preListingPayload ==> ", preListingPayload);
            console.log("appContext?.ordinalsAddress ==> ", appContext?.ordinalsAddress);
            console.log("preListingPayload.payload.signIndexes[0] ==> ", preListingPayload.payload.signIndexes[0]);

            const signedPsbt = await (window as any).unisat.signPsbt(psbt, {
                autoFinalized: false,
                toSignInputs: [{
                    address: appContext?.ordinalsAddress,
                    index: index,
                    sighashTypes: [131]
                }]
            })

            console.log("signPsbt ==> ", signedPsbt);
            let fromBase64 = false;
            if (appContext?.walletType == WalletType.Xverse) {
                fromBase64 = true;
            }

            const ListingPayload = (await axios.post(`${BACKEND_URL}/marketplace/confirm_put_on`, {
                auctionId,
                psbt: signedPsbt,
                fromBase64
            })).data;

            console.log("ListingPayload Result ==> ", ListingPayload);
            
            if (ListingPayload.success) {
                Notiflix.Notify.success(ListingPayload.message);
                Notiflix.Notify.success(ListingPayload.payload);
                onDetailsPage(list.runes[0].spacedRune);
                close();
            } else {
                Notiflix.Notify.failure(ListingPayload.message);
            }

        } catch (error) {
            console.log(error);
            return;
            // Notiflix.Notify.failure()
        }

    }

    const onSetTempListFunc = async (runeTicker: string) => {
        console.log("fetchRuneDetailsInfo runeTicker ==>", runeTicker);
        const result = (await axios.post(`${BACKEND_URL}/marketplace/getAuctionList`, {
            tick: runeTicker
        })).data;

        console.log("result on onSetTempListFunc ==> ", result);
        if (result.success) {
            const list = result.payload.list;
            const temp: IModalList[] = [];
            list.map((elem: any) => temp.push({
                txid: elem.txid,
                index: elem.index
            }))
            console.log("Filtered List ==> ", temp);
            return temp;
        } else {
            Notiflix.Notify.failure("Invalid Rune ticker...")
            return null;
        }
    }

    const onBlockRequestFunc = async (list: IRuneUtxoBalance) => {
        if(list.listed) {
            Notiflix.Notify.warning("This is already listed...");
            return
        }
        Notiflix.Notify.failure("Value of item should be greater than 0.00005 tBTC");
    }

    const onDetailsPage = async (runeTicker: string) => {
        navigation(`/runemarketplace/${runeTicker}`);
        console.log("runeTicker ==> ", runeTicker);
        LoadingInitialInfo();
        await fetchRuneDetailsInfo(runeTicker);
    }

    const onUnlist = async (list: IAuctionLIst) => {
        try {
            LoadingInitialInfo();
            const unListResult = (await axios.post(`${BACKEND_URL}/marketplace/create_put_off`, {
                auctionId: list.auctionId,
                txid: list.txid,
                index: list.index,
                btcPubkey: appContext?.paymentPublickey
            })).data;

            if (!unListResult.success) {
                Notiflix.Notify.failure(unListResult.message);
            } else {
                Notiflix.Notify.success("Get psbt successfully.");
                const toSignInputs: any[] = [];
                unListResult.payload.nftSignIndexes.map((index: number) => toSignInputs.push({
                    index,
                    address: appContext?.ordinalsAddress,
                    sighashTypes: [131]
                }))

                const signedPsbt = await (window as any).unisat.signPsbt(unListResult.payload.psbt, {
                    autoFinalized: false,
                    toSignInputs,
                })

                console.log("signedPsbt ==> ", signedPsbt);
                let fromBase64 = false;
                if(appContext?.walletType == WalletType.Xverse) {
                    fromBase64 = true;
                }

                const unListingPayload = (await axios.post(`${BACKEND_URL}/marketplace/confirm_put_off`, {
                    auctionId: list.auctionId,
                    psbt: signedPsbt,
                    fromBase64
                })).data;

                console.log("unListingPayload ==> ", unListingPayload);

                if (unListingPayload.success) {
                    Notiflix.Notify.success(unListingPayload.message);
                    Notiflix.Notify.success(unListingPayload.payload);
                    if (!tick) return
                    LoadingInitialInfo();
                    fetchRuneDetailsInfo(tick);
                } else {
                    Notiflix.Notify.failure(unListingPayload.message);
                }
            }
            Notiflix.Loading.remove();

        } catch (error) {
            console.log("error ==> ", error);
            Notiflix.Notify.info("User reject the sign");
            Notiflix.Loading.remove();
        }
    }

    useEffect(() => {
        console.log("Initial useEffect ==> ");
        LoadingInitialInfo();
        if (!tick) return
        fetchRuneDetailsInfo(tick);
    }, [])

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image" />
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">Runes Marketplace</p>
                    <div></div>
                </div>

                {/* List */}
                <div className='flex w-full'>
                    <div className='flex flex-row justify-center items-center gap-2 p-2 text-white bg-orange-500 ml-auto mr-10 px-6 rounded-lg cursor-pointer relative' onClick={() => triggerDropDownHandler()}>
                        <p className='text-center'>My assets</p>
                        <RiArrowDownSLine />
                        {dropDownModalFlag && <div className='flex flex-col absolute top-12 right-0 w-[420px] min-h-40 max-h-[600px] overflow-auto bg-gray-800 p-6'>
                            <div className='flex flex-row gap-44 text-gray-400 font-semibold mb-4'>
                                <p>Runes</p>
                                <p>Balance</p>
                            </div>
                            <div>
                                {runeBalanceList?.map(balanceList => (
                                    <div className='flex flex-row w-full gap-5 justify-center mt-3 font-semibold'>
                                        <div className='w-3/5 truncate' onClick={() => onDetailsPage(balanceList.spacedRune)}>{balanceList.spacedRune}</div>
                                        <p className='w-1/5 text-right'>{balanceList.amount}</p>
                                        <div
                                            className='p-1 rounded-md border border-white text-white w-1/5 text-center hover:brightness-150 duration-300'
                                            onClick={() => getRuneUtxoFunc(balanceList)}
                                        >
                                            List
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>

                {/* table */}
                <div className='flex flex-wrap gap-4 p-8'>
                    {auctionList && auctionList.length ? auctionList.map(list =>
                        <div className='flex flex-col gap-2 w-64 border-gray-600 border-2 rounded-lg text-white'>
                            <div className='flex flex-col bg-gray-900 p-4 gap-3 rounded-t-lg'>
                                <p className='font-medium text-sm truncate'>{list.tick}</p>
                                <p className='text-center text-2xl font-bold'>{list.amount.toLocaleString()}</p>
                                <p className='text-center font-semibold text-sm'>
                                    <span className='text-yellow-400'>{list.unitPrice}</span>
                                    {` `}sats / {` `}{list.symbol}
                                </p>
                            </div>
                            <div className='flex flex-col bg-[#1E1E1E] p-4 pt-0 rounded-b-lg'>
                                <div className='flex flex-row w-full border-b border-b-gray-600 text-yellow-700 py-2 pl-0 font-bold text-sm'>
                                    <p className='truncate w-1/2'>{list.txid}</p>
                                    <p>:{list.index}</p>
                                </div>
                                <div className='flex flex-row justify-between py-2 font-bold text-sm'>
                                    <div className='flex flex-row items-center gap-1'>
                                        <BitcoinSVG />
                                        <p>{list.price / Math.pow(10, 8)}</p>
                                    </div>
                                    <p>$N/A</p>
                                </div>
                                {list.address == appContext?.ordinalsAddress ?
                                    <div className='border border-red-600 text-center text-red-600 p-2 mt-1 font-bold cursor-pointer hover:brightness-150 duration-200' onClick={() => onUnlist(list)}>
                                        Unlist
                                    </div> :
                                    <div className='border border-gray-500 text-center p-2 mt-1 font-bold cursor-pointer hover:brightness-150 duration-200' onClick={() => onCreateBid(list)}>
                                        Buy
                                    </div>}

                            </div>
                        </div>)
                        :
                        <div className='flex flex-col justify-center mx-auto mt-40'>
                            <div className='flex mx-auto scale-150'><EmptySVG /></ div>
                            <p className='w-full mt-10 text-center text-xl text-gray-500'>There is no runes listed...</p>
                        </div>
                    }

                </div>
                <Modal opened={opened} onClose={close} centered withCloseButton={false} size={'xl'}>
                    {/* Modal content */}
                    {runeUtxoList?.length ?
                        <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-solid border-[#252B35] text-white w-full p-5 px-8 Proto-Mono">
                            <p className='text-white font-bold text-xl text-center mt-4'>
                                List <span className='text-orange-500 font-bold'>{selectedList?.spacedRune}</span> for sale
                            </p>
                            <div className='flex flex-row mt-4'>
                                <p className=''>Balance: <span className='text-orange-500'>{selectedList?.amount} {selectedList?.symbol}</span></p>
                            </div>
                            <div className='flex flex-wrap gap-4 p-4 mt-4'>
                                {runeUtxoList.map(list => (parseInt(price) * list.runes[0].amount / Math.pow(10, 8)) < 0.00005 ?
                                    <div className={`flex flex-col rounded-lg border border-gray-500 text-white w-40 text-sm cursor-not-allowed`} onClick={() => onBlockRequestFunc(list)}>
                                        <div className='bg-[#121212] rounded-t-lg flex flex-col p-2 pr-0 pb-0 gap-3 '>
                                            <p className='text-left'>{selectedList?.runeid}</p>
                                            <p className='text-2xl font-bold text-center'>{list.runes[0].amount}</p>
                                            <div className='flex justify-end'>
                                                <p className='bg-gray-700 text-white text-right px-1'>{list.satoshi} sats</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col bg-[#303340] rounded-b-lg text-orange-400 p-2 font-medium '>
                                            <div className='flex flex-row'>
                                                <p className='truncate'>{list.txid}</p>
                                                <p className=''>:{list.vout}</p>
                                            </div>
                                            {price && <div className='flex flex-row items-center gap-1 border-t border-t-gray-500 p-1'>
                                                <BitcoinSVG />
                                                <p className='text-red-500'>
                                                    {(parseInt(price) * list.runes[0].amount / Math.pow(10, 8))}
                                                </p>
                                            </div>}
                                        </div>
                                    </div> :
                                    <div className={`flex flex-col rounded-lg border ${list.listed ? ' border-gray-900 text-gray-600' : ' border-gray-500 text-white'}  w-40 text-sm ${list.listed ? 'cursor-not-allowed' : 'cursor-pointer '} hover:brightness-150 duration-300`} onClick={() => onListFunc(list)}>
                                        <div className='bg-[#121212] rounded-t-lg flex flex-col p-2 pr-0 pb-0 gap-3 '>
                                            <p className='text-left'>{selectedList?.runeid}</p>
                                            <p className='text-2xl font-bold text-center'>{list.runes[0].amount}</p>
                                            <div className='flex justify-end'>
                                                <p className={`bg-gray-700 ${list.listed ? 'text-gray-400' : 'text-white'} text-right px-1`}>{list.satoshi} sats</p>
                                            </div>
                                        </div>
                                        <div className={`flex flex-col bg-[#303340] rounded-b-lg ${list.listed ? ' text-orange-700' : 'text-orange-400'} p-2 font-medium`}>
                                            <div className='flex flex-row'>
                                                <p className='truncate'>{list.txid}</p>
                                                <p className=''>:{list.vout}</p>
                                            </div>
                                            {price && <div className='flex flex-row items-center gap-1 border-t border-t-gray-500 p-1'>
                                                <BitcoinSVG />
                                                <p className=''>
                                                    {(parseInt(price) * list.runes[0].amount / Math.pow(10, 8))}
                                                </p>
                                            </div>}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Price Input */}
                            <div className='flex flex-row w-full text-white mt-8 text-sm'>
                                <div className='flex justify-start border border-gray-600 border-r-0 rounded-l-lg p-2 px-3'>Price</div>
                                <div className='flex items-center p-2 flex-grow border border-gray-600 hover:border-orange-400 duration-700'>
                                    <input
                                        ref={unitPriceRef}
                                        className='w-full my-auto bg-transparent outline-none'
                                        onChange={(e) => onChangeFunc(e)}
                                    >
                                    </input>
                                </div>
                                <div className='flex justify-end border border-gray-600 border-l-0 rounded-r-lg p-2 px-3'>sats/$</div>
                            </div>
                            <p className='text-gray-400 text-sm my-4'>Note: the value of each item should be between 0.00005 tBTCand 5 tBTC.</p>
                        </div> : <></>}
                </Modal>
            </div>
            <ToastContainer />
        </div>
    )
}

