import { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IoCopyOutline, IoLogOutOutline } from 'react-icons/io5';

import { BACKEND_URL, BACKEND_URL_PURE } from "../configs/config";
import { AppContext } from "../context/UserContext";

import { IProfile } from '../type';

export default function Header() {

    const navigate = useNavigate();
    const appContext = useContext(AppContext)

    const [profile, setProfile] = useState<IProfile>();
    const [headerFlag, setHeaderFlag] = useState(false);
    const [isAvatarloading, setIsAvatarloading] = useState(true);

    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            if (response.data.success) {
                // console.log(response.data.payload);
                return response.data.payload
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchSpecificProfiles = async () => {
        try {
            const data = await getProfileByPaymentAddress();
            setProfile(data);
            appContext?.updateProfileId(data._id);
            console.log(data);
            setIsAvatarloading(false);
        } catch (error) {
            // Display an error message to the user
        }
    };
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(`${appContext?.paymentAddress}`);
            toast.success("address was copied!");
        } catch (err) {

            alert("Copy to clipboard failed.");
        }
    };
    const handleLogoutClick = () => {
        navigate("/")
        localStorage.clear();
        console.log(appContext?.paymentAddress);
        appContext?.updatePaymentAddress("");
    }

    useEffect(() => {
        fetchSpecificProfiles();
    }, []);

    return (
        <div className="relative flex flex-col h-[53px] min-[450px]:bg-[#1d2027] border-[#26292e] broder-1 items-center justify-between px-2 py-1 rounded-xl gap-2 drop-shadow-2xl border-2 min-[450px]:pr-8" onClick={() => setHeaderFlag(flag => !flag)}>
            <div className="flex flex-row">
                {
                    !isAvatarloading ?
                        <img src={`${BACKEND_URL_PURE}/${profile?.avatar}`} className="rounded-[9px] h-10 w-10" alt="Newbie" />
                        :
                        <div className="w-10 h-10 mx-auto rounded-md">
                            <div className="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
                                <div className="w-10 h-10 bg-gray-700 rounded-md"></div>
                            </div>
                        </div>
                }
                <div className="flex flex-col pl-2 justify-center gap-1 max-[450px]:hidden">
                    {/* {profile ?
                                    (<p className="text-white font-semibold text-[16px] leading-4">{profile?.username}</p>) :
                                    (<></>)
                                } */}
                    {!isAvatarloading ? profile ?
                        (<p className="text-white font-semibold text-[16px] leading-4">{profile?.username}</p>) :
                        (<p className="text-white font-semibold text-[12px] leading-4">Guest</p>) :
                        <div className="w-[110px] h-4 mx-auto rounded-md">
                            <div className="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
                                <div className="w-full h-4 bg-gray-700 rounded-md"></div>
                            </div>
                        </div>}
                    {!isAvatarloading ?
                        <div className="flex flex-row duration-300 cursor-pointer hover:brightness-200" onClick={handleCopyClick}>
                            <p className="truncate text-[12px] font-bold text-[#637592] max-w-[90px] leading-4">{appContext?.paymentAddress} </p>
                            <IoCopyOutline style={{ color: "#637592" }} className="w-3.5 h-3.5" />
                        </div> :
                        <div className="w-[110px] h-4 mx-auto rounded-md">
                            <div className="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
                                <div className="w-full h-4 bg-gray-700 rounded-md"></div>
                            </div>
                        </div>}
                </div>
            </div>
            {headerFlag ?
                <div className="absolute mt-12 min-w-[195px] right-0 rounded-[12px] py-2 flex flex-col bg-[#1d2027] border-t-2 border-[#26292e] text-white drop-shadow-xl">
                    {/* <div className="flex flex-row p-2 pl-5 gap-4 items-center hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                        <FaRegCircleUser size={20} />
                        <p className="text-[16px] font-semibold">Wallet Settings</p>
                    </div>
                    <div className="flex flex-row p-2 pl-5 gap-4 items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                        <FaPlus size={20} />
                        <p className="text-[16px] font-semibold">Add wallet</p>
                    </div> */}
                    <div className="flex flex-row p-2 pl-5 gap-4 text-red-600  items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer"
                        onClick={handleLogoutClick}
                    >
                        <IoLogOutOutline size={20} />
                        <p className="text-[16px] font-semibold">Log out</p>
                    </div>
                </div> : <></>}
        </div>
    )
}
